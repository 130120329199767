import { createI18n } from "vue-i18n";
import EN from "./locale-en";

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  globalInjection: true,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: EN
  }
});

export default i18n;
